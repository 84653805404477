// Angular
import { ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { DOCUMENT } from '@angular/common';
// Service
import { ThemeModeService } from './_metronic/layout/components/sidebar/theme-mode-switcher/theme-mode.service';
import { SplashScreenService } from './_metronic/layout/components/splash-screen/splash-screen.service';
import { CommonService } from '@core/utils';
import { AuthService } from '@auth-service';
// Rxjs
import { Subscription, filter, noop } from 'rxjs';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body[app-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {

  // Private variables
  private unsubscribe: Subscription[] = [];

  /**
   * Creates an instance of AppComponent.
   * @param {Router} router
   * @param {ThemeModeService} modeService
   * @param {SplashScreenService} splashScreenService
   * @param {CommonService} cs
   * @param {SwPush} swPush
   * @param {SwUpdate} swUpdate
   * @param {AuthService} authService
   * @param {Document} document
   * @memberof AppComponent
   */
  constructor(
    readonly router: Router,
    private modeService: ThemeModeService,
    private splashScreenService: SplashScreenService,
    readonly cs: CommonService,
    readonly swPush: SwPush,
    readonly swUpdate: SwUpdate,
    readonly authService: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setFaviconBasedOnDomain();
  }

  /**
   * OnInit
   * @memberof AppComponent
   */
  ngOnInit(): void {
    this.modeService.init();

    const routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      // Hide splash screen
      this.splashScreenService.hide();

      // Scroll to top on every route change
      window.scrollTo(0, 0);
    });
    this.unsubscribe.push(routerSubscription);

    // Service Worker
    this.swUpdate.isEnabled ? this.handleNewAppVersionUpdate() : noop();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
    this.authService.flushRoleAndPermissions();
  }

  /* Keyboard shortcut for opening company */
  @HostListener('window:keydown', ['$event'])
  bigFont(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'b') {
      event.preventDefault();
      // Open a confirmation dialog to enter company ID
      const companyId = window.prompt('Enter Company ID:');
      if (companyId) {
        this.router.navigate([`/register-management/accounts/companies/company-details/${companyId}/ABC`]);
      }
    }
  }

  /**
   * Set Favicon Based on Domain
   * @private
   * @memberof AppComponent
   */
  private setFaviconBasedOnDomain(): void {
    const hostname = window.location.hostname;
    const subdomain = this.getSubdomain(hostname);
    if (subdomain) {
      const faviconSrc = `assets/media/logos/${subdomain}/favicon.ico`;
      const favicon: HTMLLinkElement = this.document.querySelector('#appFavicon') as HTMLLinkElement;
      favicon.setAttribute('href', faviconSrc);
    }
  }

  /**
   * Get Subdomain
   * @private
   * @param {string} hostname
   * @returns {(string)}
   * @memberof AppComponent
   */
  private getSubdomain(hostname: string): string {
    const parts = hostname.split('.');
    if (parts.length >= 3) {
      return parts[1];
    }
    return 'bildelsbasen';
  }

  /**
   * Informing the user that a new version is available
   * For long-running SPA applications that the user might have opened for hours,
   * we might want to check periodically to see if there is a new version of the application on the server and install it in the background.
   */
  private handleNewAppVersionUpdate(): void {
    this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')).subscribe(() => {
        if (confirm('New version available. Load New Version?')) {
          this.swUpdate.activateUpdate().then(() => window.location.reload());
        }
      });
  }

}
