// Angular
import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  input
} from '@angular/core';

/**
 * Directive to lazy load image and add fallack image on image error load
 * @export
 * @class ImageLoaderDirective
 * @implements {OnInit}
 * @implements {AfterContentInit}
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img[imageLoader]',
    standalone: false
})
export class ImageLoaderDirective implements OnInit, AfterContentInit {

    // INPUTS
    public readonly src = input.required<string>();
    public readonly loaderSrc = input<string>('assets/media/placholder/no-logo.png');
    public readonly errorSrc = input<string>('assets/media/placholder/no-logo.png');
    public readonly lazyLoad = input<boolean>(true);

    // Private
    private alreadyTriedLoading: boolean = false;
    private alreadyTriedError: boolean = false;

    /**
     * Creates an instance of ImageLoaderDirective.
     * @param {ElementRef<HTMLImageElement>} el
     * @param {Renderer2} renderer
     * @param {Object} platformId
     * @memberof ImageLoaderDirective
     */
    constructor(
        private el: ElementRef<HTMLImageElement>,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    /**
     * On Init
     * @memberof ImageLoaderDirective
     */
    ngOnInit(): void {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.src());

        if (this.lazyLoad()) {
            this.renderer.setAttribute(this.el.nativeElement, 'loading', 'lazy');
        }
    }

    /**
     * After Content Init
     * @memberof ImageLoaderDirective
     */
    ngAfterContentInit(): void {
        if (this.shouldDisplayLoader()) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.loaderSrc());
        } else {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.src());
        }
    }

    @HostListener('load')
    public onLoad(): void {
        if (!this.alreadyTriedLoading) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.src());
        }
        this.alreadyTriedLoading = true;
    }

    @HostListener('error')
    public onError(): void {
        if (!this.alreadyTriedError) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.errorSrc());
        }
        this.alreadyTriedError = true;
    }

    private shouldDisplayLoader(): boolean {
        return (
            isPlatformBrowser(this.platformId) && !this.el.nativeElement.complete
        );
    }
}