// Angular
import { Component, OnInit, ElementRef, viewChild } from '@angular/core';
// Service
import { SplashScreenService } from './splash-screen.service';
import { LogoService } from '../../core/logo.service';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
    standalone: false
})
export class SplashScreenComponent implements OnInit {

  // Public 
  logoSrc: string;

  // View Child
  readonly splashScreen = viewChild<ElementRef>('splashScreen');

  /**
   * Creates an instance of SplashScreenComponent.
   * @param {LogoService} logoService
   * @param {SplashScreenService} splashScreenService
   * @memberof SplashScreenComponent
   */
  constructor(
    private readonly logoService: LogoService,
    private splashScreenService: SplashScreenService) { }

  /**
   * On init
   * @memberof SplashScreenComponent
   */
  ngOnInit(): void {
    this.logoSrc = this.logoService.getLogoSrc();
    this.splashScreenService.init(this.splashScreen());
  }
}
