<!--begin::User-->
@if (profile$ | async; as _profile;) {
<div id="user-info" ngbDropdown display="dynamic">
  <!--begin::User info-->
  <div ngbDropdownToggle id="userQuickDropdown" class="btn d-flex align-items-center text-start">
    <div class="d-flex align-items-sm-center justify-content-center">
      <!--begin::Symbol-->
      <div class="symbol symbol-50px">
        <img [src]="_profile?.user?.gravatar" alt="User">
        <div class="position-absolute translate-middle top-0 start-100 mb-6 bg-success rounded-circle h-8px w-8px">
        </div>
      </div>
      <!--end::Symbol-->
      <!--begin::Wrapper-->
      <div class="app-sidebar-user-default flex-row-fluid flex-wrap ms-3">
        <!--begin::Section-->
        <div class="d-flex">
          <!--begin::Info-->
          <div class="flex-grow-1 me-2">
            <!--begin::Name-->
            <span class="text-white fs-7 fw-bold"> {{ _profile?.user?.name }}</span>
            <!--end::Name-->
            <!--begin::Company-->
            <span class="text-white fw-semibold d-block fs-8"> {{ _profile?.company?.name }}</span>
            <!--end::Company-->
            <!--begin::Role-->
            <div class="d-flex align-items-center text-white fs-9">
              {{_profile?.role?.role}}
            </div>
            <!--end::Role-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Section-->
      </div>
      <!--end::Wrapper-->
    </div>
  </div>
  <!--end::User info-->
  <!--begin::User account menu-->
  <div ngbDropdownMenu aria-labelledby="userQuickDropdown"
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-700 menu-state-bg menu-state-color fw-semibold py-2 fs-6 w-250px ms-2">
    <!--begin::Menu item-->
    <div class="menu-item px-3" *ngxPermissionsOnly="['canAccessCompanyProfile']">
      <a [routerLink]="['register-management','accounts','companies','company-details',_profile.company?.id,(_profile?.company?.name|makemepretty)]"
        class="menu-link px-5" transloco="label_company_profile">
      </a>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <app-theme-mode-switcher />
    <!--end::Menu item-->
    <!--begin::Menu item-->
    @if (_profile?.company_access.length>1 || isSuperAdmin) {
    <div class="menu-item px-3" ngbDropdown display="dynamic">
      <a ngbDropdownToggle id="userAccessCompanyDropdown" class="menu-link text-hover-primary px-5 cursor-pointer">
        <span class="menu-title text-hover-primary" transloco="label_access_companies"></span>
        <span class="badge badge-light-primary">{{_profile?.company_access.length}}</span>
      </a>
      <div ngbDropdownMenu aria-labelledby="userAccessCompanyDropdown"
        class="menu-sub menu-sub-dropdown py-4 mh-400px overflow-auto">
        @for (company of _profile?.company_access; track company?.id) {
        <div [ngClass]="{'active': company.id ===_profile.company?.id}"
          (click)="onAccessCompany(company, _profile.company?.name)" class="menu-item px-3">
          <a href="javascript:;" class="menu-link text-hover-primary px-5 cursor-pointer"
            [ngClass]="{'active': company.id ===_profile.company?.id}">
            {{company.name}}
            @if (company.id ===_profile.company?.id) {
            <i class="fa fa-check text-primary ms-2"></i>
            }
          </a>
        </div>
        }
      </div>
    </div>
    }
    <!--end::Menu item-->
    <div class="separator my-2"></div>
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <a (click)="openLogoutConfirmation()" class="menu-link text-hover-primary px-5 cursor-pointer"
        transloco="label_log_out"></a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::User account menu-->
</div>
}
<!--end::User -->