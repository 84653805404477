// Angular
import { Component } from '@angular/core';

@Component({
    selector: 'app-sidebar-footer',
    templateUrl: './sidebar-footer.component.html',
    styleUrls: ['./sidebar-footer.component.scss'],
    standalone: false
})
export class SidebarFooterComponent {
}
