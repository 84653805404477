// Angular
import { Component, OnInit, input } from '@angular/core';

@Component({
  selector: 'app-sidebar-logo',
  templateUrl: './sidebar-logo.component.html',
  styleUrls: ['./sidebar-logo.component.scss'],
  standalone: false
})
export class SidebarLogoComponent implements OnInit {

  // Public
  toggleAttr: string;

  // Input
  readonly toggleButtonClass = input<string>('');
  readonly toggleEnabled = input<boolean>();
  readonly toggleType = input<string>('');
  readonly toggleState = input<string>('');

  /**
   * OnInit
   * @memberof SidebarLogoComponent
   */
  ngOnInit(): void {
    this.toggleAttr = `app-sidebar-${this.toggleType()}`;
  }

}
