// Angular
import { Component, effect, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Service
import { LayoutService } from '../../core/layout.service';
// Components
import { ToggleComponent, MenuComponent, ScrollComponent, DrawerComponent } from "../../../kt/components";

@Component({
  selector: 'app-scripts-init',
  templateUrl: './scripts-init.component.html',
  standalone: false
})
export class ScriptsInitComponent implements OnInit, OnDestroy {

  // Subscriptions
  private unsubscribe: Subscription[] = [];
  private timmers: Array<null | ReturnType<typeof setTimeout>> = [];

  /**
   * Component constructor
   * @param layout: LayoutService
   */
  constructor(
    private layout: LayoutService) {
    // Reactively update properties when layoutConfig changes
    effect(() => {
      const config = this.layout.layoutConfig();
      this.pluginsReInitialization();
    });
  }

  /**
   * On Init
   * @memberof ScriptsInitComponent
   */
  ngOnInit(): void {
    this.pluginsInitialization();
  }

  /**
   * Plugins Initialization
   * @memberof ScriptsInitComponent
   */
  pluginsInitialization() {
    const pluginInitTimmer = setTimeout(() => {
      ToggleComponent.bootstrap();
      DrawerComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
    }, 200);
    this.timmers.push(pluginInitTimmer);

  }

  /**
   * Plugins ReInitialization
   * @memberof ScriptsInitComponent
   */
  pluginsReInitialization(): void {
    const pluginInitTimmer = setTimeout(() => {
      ToggleComponent.reinitialization();
      DrawerComponent.reinitialization();
      MenuComponent.reinitialization();
      ScrollComponent.reinitialization();
    }, 100);
    this.timmers.push(pluginInitTimmer);
  }

  /**
   * On Destroy
   * @memberof ScriptsInitComponent
   */
  ngOnDestroy() {
    this.timmers.forEach((timmer: any) => clearTimeout(timmer));
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
