// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns filtered list based on search string
 */
@Pipe({
    name: 'searchFilter',
    pure: true,
    standalone: false
})
export class SearchFilterPipe implements PipeTransform {

  /**
   * Transform
   * @param items: any[]
   * @param field: string
   * @param value: string
   */
  transform<T>(items: T[], field: string | string[], value: string): T[] {
    if (!items || items.length === 0) return [];
    if (!value || value.length === 0) return items;

    // Normalize the field parameter to always be an array
    const fields = Array.isArray(field) ? field : [field];

    return items.filter(item => {
      return fields.some(f => {
        const fieldValue: any = this.getFieldValue<T>(item, f);

        // Convert the fieldValue to a string, whether it's a number or a string
        const fieldValueStr = fieldValue !== undefined && fieldValue !== null ? fieldValue.toString() : '';

        // Apply the search logic, converting the value to lowercase if it's a string
        return fieldValueStr.toLowerCase().includes(value.toLowerCase());
      });
    });
  }

  /**
   * Get Filed values
   * @private
   * @template T
   * @param {*} item
   * @param {string} field
   * @returns {string}
   * @memberof SearchFilterPipe
   */
  private getFieldValue<T>(item: any, field: string): string {
    return field.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : '', item) || '';
  }
}
