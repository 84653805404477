// Angular
import { Component, OnDestroy, Input } from '@angular/core';
// Rxjs
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Service
import { AuthService } from '@auth-service';
// Environment
import { environment } from '@environment';
import { SentryService } from '@core/utils';
// Material
import { MatSnackBar } from '@angular/material/snack-bar';
// App Version
import packageInfo from 'package.json';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent implements OnDestroy {

  // Public
  appVersion: string = packageInfo.version;
  currentDateStr: string = new Date().getFullYear().toString();

  // Private
  private unsubscribe = new Subject<void>();

  /**
   * Creates an instance of FooterComponent.
   * @param {SentryService} sentryService
   * @param {AuthService} authService
   * @param {MatSnackBar} snackBar
   * @memberof FooterComponent
   */
  constructor(
    private readonly sentryService: SentryService,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar
  ) { }


  /**
   * On Destroy
   * @summary To avoid memory leak destroy component subscription
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Open sentry Feedback modal
   */
  openSentryDialog(): void {
    if (environment.isSentryLoggingEnable) {
      this.authService
        .getUserDetails()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.sentryService.openFeedbackDialog(data.user);
        });
    } else {
      this.snackBar.open('Sentry logging is disabled. please check environment configuration.', 'Close', {
        panelClass: ['success-dialog']
      });
    }
  }
}
