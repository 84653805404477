<app-user-info></app-user-info>

@if (toggleEnabled()) {
  <!--begin::Sidebar toggle-->
  <div id="kt_app_sidebar_toggle"
    class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
    [ngClass]="toggleButtonClass()" data-kt-toggle="true" [attr.data-kt-toggle-state]="toggleState()"
    data-kt-toggle-target="body" [attr.data-kt-toggle-name]="toggleAttr">
    <i class="fa fa-arrow-left rotate-180"></i>
  </div>
  <!--end::Sidebar toggle-->
}