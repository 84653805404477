// Angular
import { Component, OnInit } from '@angular/core';
// Service
import { ThemeModeService, ThemeModeType } from './theme-mode.service';

@Component({
  selector: 'app-theme-mode-switcher',
  templateUrl: './theme-mode-switcher.component.html',
  styleUrls: ['./theme-mode-switcher.component.scss'],
  standalone: false
})
export class ThemeModeSwitcherComponent implements OnInit {

  // Public signals
  mode: ThemeModeType;
  menuMode: ThemeModeType;

  /**
   * Creates an instance of ThemeModeSwitcherComponent.
   * @param {ThemeModeService} modeService
   * @memberof ThemeModeSwitcherComponent
   */
  constructor(private readonly modeService: ThemeModeService) {
  }

  /**
   * OnInit
   * @memberof ThemeModeSwitcherComponent
   */
  ngOnInit(): void {
    this.mode = this.modeService.mode();
    this.menuMode = this.modeService.menuMode();
  }

  /**
   * Switch mode
   * @param {ThemeModeType} _mode
   * @memberof ThemeModeSwitcherComponent
   */
  switchMode(_mode: ThemeModeType): void {
    this.modeService.switchMode(_mode);
  }
}
