// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns Vehicle Make/Brand Logo
 */
@Pipe({
    name: 'makeLogo',
    standalone: false
})
export class MakeLogoPipe implements PipeTransform {

  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    let pretty = value.toLowerCase();
    pretty = pretty.replace(/\-/g, '_');
    pretty = pretty.replace(/\s/g, '-');
    pretty = pretty.replace(/\//g, '&');
    pretty = pretty.replace(/\+/g, '%2');
    return `https://www.bildelsbasen.se/styles/webp-images/brand-logos/${pretty}.webp`;
  }
}
