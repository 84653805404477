// Angular
import { Component, HostBinding, OnInit, input } from '@angular/core';
// Icons
import icons from './icons.json';

@Component({
    selector: 'app-keenicon',
    templateUrl: './keenicon.component.html',
    styleUrls: ['./keenicon.component.scss'],
    standalone: false
})
export class KeeniconComponent implements OnInit {

  // Public
  pathsNumber: number = 0;

  // Input
  readonly name = input<string>();
  readonly class = input<string>();
  readonly type = input<string>('duotone');

  /**
   * Creates an instance of KeeniconComponent.
   * @memberof KeeniconComponent
   */
  constructor() { }

  /**
   * OnInit
   * @memberof KeeniconComponent
   */
  ngOnInit() {
    const type = this.type();
    if (type === 'duotone') {
      // @ts-ignore
      this.pathsNumber = icons[type + '-paths'][this.name()] ?? 0;
    }
  }

  @HostBinding('style.display')
  get styleDisplay() {
    return 'contents';
  }
}
