// Angular
import { Injectable, signal } from '@angular/core';
// Component
import { ThemeModeComponent } from 'src/app/_metronic/kt/layout';
// Theme modes
export type ThemeModeType = 'dark' | 'light';
const themeModeLSKey = 'prime_theme_mode_value';
const themeMenuModeLSKey = 'prime_theme_mode_menu';

/**
 * Get theme mode from localstorage
 * @param {string} lsKey
 * @return {*}  {ThemeModeType}
 */
const getThemeModeFromLocalStorage = (lsKey: string): ThemeModeType => {
  if (!localStorage) {
    return 'light';
  }

  const data = localStorage.getItem(lsKey);
  if (data === 'dark') {
    return 'dark';
  }

  return 'light';
};

@Injectable({
  providedIn: 'root',
})
export class ThemeModeService {
  // Public signals for theme mode and menu mode
  public mode = signal<ThemeModeType>(getThemeModeFromLocalStorage(themeModeLSKey));
  public menuMode = signal<ThemeModeType>(getThemeModeFromLocalStorage(themeMenuModeLSKey));

  /**
   * Creates an instance of ThemeModeService.
   * @memberof ThemeModeService
   */
  constructor() {}

  /**
   * Update theme mode
   * @param {ThemeModeType} _mode
   * @memberof ThemeModeService
   */
  public updateMode(_mode: ThemeModeType) {
    this.mode.set(_mode); // Update the signal
    if (localStorage) {
      localStorage.setItem(themeModeLSKey, _mode);
    }
    document.documentElement.setAttribute('data-bs-theme', _mode);
    ThemeModeComponent.init();
  }

  /**
   * Update menu theme mode
   * @param {ThemeModeType} _menuMode
   * @memberof ThemeModeService
   */
  public updateMenuMode(_menuMode: ThemeModeType) {
    this.menuMode.set(_menuMode); // Update the signal
    if (localStorage) {
      localStorage.setItem(themeMenuModeLSKey, _menuMode);
    }
  }

  /**
   * Init function
   * @memberof ThemeModeService
   */
  public init() {
    this.updateMode(this.mode()); // Access the signal value using ()
    this.updateMenuMode(this.menuMode());
  }

  /**
   * Switch theme mode
   * @param {ThemeModeType} _mode
   * @memberof ThemeModeService
   */
  public switchMode(_mode: ThemeModeType) {
    if (localStorage) {
      localStorage.setItem(themeModeLSKey, _mode);
      localStorage.setItem(themeMenuModeLSKey, _mode);
    }
    document.location.reload();
  }
}
