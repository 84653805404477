import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
// Angular
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Material
import { MatSnackBar } from '@angular/material/snack-bar';
// Constants
import { APP_CONSTANT } from '@constants';
// Service
import { CommonService, GlobalErrorHandler } from '@core/utils';
import { AuthService } from '@auth-service';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Store
import { Store } from '@ngrx/store';
import { IUserLogin, Login } from 'src/app/core/auth';
import { AppState } from 'src/app/core/reducers';
// Lodash & RxJs
import { get } from 'lodash';
import { catchError, Subject, takeUntil, tap } from 'rxjs';

// Interface
interface JwtPayload {
  role: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  company_id: number;
  include_usercompany: boolean;
  admin: boolean;
  impersonating: boolean;
  title_id: number;
  author: boolean;
}


@Component({
    selector: 'app-impersonation-mode-banner',
    templateUrl: './impersonation-mode-banner.component.html',
    styleUrls: ['./impersonation-mode-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ImpersonationModeBannerComponent implements OnInit, OnDestroy {

  // Public
  isImpersonating: boolean = false;
  loading: boolean = false;

  // Private
  private unsubscribe = new Subject<void>();

  /**
   * Creates an instance of ImpersonationModeBannerComponent.
   * @param {CommonService} commonService
   * @param {Router} router
   * @param {AuthService} auth
   * @param {Store<AppState>} store
   * @param {MatSnackBar} snackBar
   * @param {TranslocoService} translocoService
   * @param {GlobalErrorHandler} globalErrorHandler
   * @param {Document} document
   * @memberof ImpersonationModeBannerComponent
   */
  constructor(
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly store: Store<AppState>,
    private readonly snackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
    private readonly cdr: ChangeDetectorRef,
    private readonly globalErrorHandler: GlobalErrorHandler,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  /**
   * On Init
   * @memberof ImpersonationModeBannerComponent
   */
  ngOnInit(): void {
    try {
      const jwt: string = this.commonService.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
      const payload = this._decodeJwtPayload(jwt);
      this.isImpersonating = get(payload, 'impersonating', false);
    } catch (error) {
    }
  }

  /**
   * On Destroy
   * @memberof ImpersonationModeBannerComponent
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  /**
   * On Closing banner go back to original login
   * @memberof ImpersonationModeBannerComponent
   */
  onCloseImpersonation(): void {
    this.loading = true;
    const element = this.document.getElementById('kt_app_root');
    if (element) { element.classList.add('opacity-50'); }
    const loginPayload: IUserLogin = {
      username: null,
      password: null,
      company_id: null,
      company_default: null,
    };
    this.auth.login(loginPayload, { beforeImpersonteJwtToken: true }).pipe(
      tap((response: any) => this._handleResponse(response)),
      catchError(error => this.globalErrorHandler.handleError('on-access', error)),
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  /**
   * Handle response
   * @private
   * @param {*} response
   * @memberof ImpersonationModeBannerComponent
   */
  private _handleResponse(response: any): void {
    if (response && response.success) {
      this.store.dispatch(
        new Login({
          user: get(response, 'data'),
          authToken: get(response, 'data.token.legacy'),
        })
      );
      this.router.navigate(['/redirect'], {
        queryParams: { returnUrl: null },
      }).then(() => {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.document.location.reload();
        this.commonService.removeFromLocalStorage(APP_CONSTANT.BEFORE_IMPERSONATE_JWT_KEY)
      });
    } else {
      this.loading = false;
      const element = this.document.getElementById('kt_app_root');
      if (element) { element.classList.remove('opacity-50'); }
      this.snackBar.open(this.translocoService.translate('sentence_something_went_wrong'), 'Close', {
        panelClass: ['danger-dialog']
      });
      this.cdr.detectChanges();
    }
  }


  /**
   * Function to decode a Base64 URL-encoded string
   * @private
   * @param {string} base64Url
   * @returns {string}
   * @memberof ImpersonationModeBannerComponent
   */
  private _base64UrlDecode(base64Url: string): string {
    try {
      // Replace URL-specific characters with base64 characters
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      // Pad with trailing equal signs if necessary
      while (base64.length % 4 !== 0) {
        base64 += '=';
      }
      // Decode from Base64
      const decodedString = atob(base64);
      return decodedString;
    } catch (error) {
      return '';
    }
  }

  /**
   * Function to decode the JWT payload
   * @private
   * @param {string} token
   * @return {*}  {(JwtPayload | null)}
   * @memberof ImpersonationModeBannerComponent
   */
  private _decodeJwtPayload(token: string): JwtPayload | null {
    try {
      // Split the JWT into its parts
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('Invalid JWT format');
      }
      // Decode the payload (the second part of the JWT)
      const payload = this._base64UrlDecode(parts[1]);
      // Parse the JSON payload
      return JSON.parse(payload);
    } catch (error) {
      return null;
    }
  }

}
