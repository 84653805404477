<!-- begin::Menu toggle -->
<div ngbDropdown display="dynamic" placement="end">

  <div ngbDropdownToggle id="themeModeDropdown">
    <div class="menu-item px-3">
      <a href="javascript:;" class="menu-link px-5">
        Theme
        <span class="position-absolute translate-middle-y top-50 end-0 pe-10">
          @if (mode) {
            @if (mode === 'dark') {
              <app-keenicon name="moon" class="fs-2 fs-lg-1 theme-light-hide svg-icon-2"></app-keenicon>
            }
            @if (mode === 'light') {
              <app-keenicon name="night-day" class="fs-2 fs-lg-1 theme-dark-hide svg-icon-2"></app-keenicon>
            }
          }
        </span>

      </a>
    </div>

  </div>
  <!-- end::Menu toggle -->

  <!-- begin::Menu -->
  <div ngbDropdownMenu aria-labelledby="themeModeDropdown"
    class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
    data-kt-menu='true'>
    @if (menuMode) {
      <!-- begin::Menu item  -->
      <div class='menu-item px-3 my-0'>
        <a class="menu-link px-3 py-2" [ngClass]="{'active': menuMode === 'light'}" (click)="switchMode('light')">
          <span class='menu-icon' data-kt-element='icon'>
            <app-keenicon name="night-day" class="fs-3 text-dark"></app-keenicon>
          </span>
          <span class='menu-title'>Light</span>
        </a>
      </div>
      <!-- end::Menu item -->
      <!-- begin::Menu item  -->
      <div class='menu-item px-3 my-0'>
        <a class="menu-link px-3 py-2" [ngClass]="{'active': menuMode === 'dark'}" (click)="switchMode('dark')">
          <span class='menu-icon' data-kt-element='icon'>
            <app-keenicon name="moon" class="fs-3 text-dark"></app-keenicon>
          </span>
          <span class='menu-title'>Dark</span>
        </a>
      </div>
      <!-- end::Menu item -->
    }

  </div>
  <!-- end::Menu -->
</div>