// Angular
import { Injectable, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';
// Config
import { LayoutType, ILayout, CSSClassesType, HTMLAttributesType } from './configs/config';
import { DarkSidebarConfig } from './configs/dark-sidebar.config';
// App Version
import packageInfo from 'package.json';
// Constants
const LAYOUT_CONFIG_LOCAL_STORAGE_KEY = `${packageInfo.version}-layoutConfig`;
const BASE_LAYOUT_TYPE_LOCAL_STORAGE_KEY = `${packageInfo.version}-baseLayoutType`;
const defaultBaseLayoutType: LayoutType = 'dark-sidebar';
const defaultLayoutConfig: ILayout = DarkSidebarConfig;

/**
 * Get Empty HTML Attributes
 * @export
 * @return {*}  {HTMLAttributesType}
 */
export function getEmptyHTMLAttributes(): HTMLAttributesType {
  return {
    asideMenu: {},
    headerMobile: {},
    headerMenu: {},
    headerContainer: {},
  };
}

/**
 * Get Empty Css Classes
 * @export
 * @return {*}  {CSSClassesType}
 */
export function getEmptyCssClasses(): CSSClassesType {
  return {
    header: [],
    headerContainer: [],
    headerMobile: [],
    headerMenu: [],
    aside: [],
    asideMenu: [],
    asideToggle: [],
    toolbar: [],
    toolbarContainer: [],
    content: [],
    contentContainer: [],
    footerContainer: [],
    sidebar: [],
    pageTitle: [],
    wrapper: [],
  };
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  // Signal to track current layout type
  public currentLayoutType = signal<LayoutType | null>(null);

  // Signal to track layout configuration
  public layoutConfig = signal<ILayout>(
    this.getLayoutConfig(this.getBaseLayoutTypeFromRouteOrLocalStorage())
  );

  // Signal to track CSS classes
  private classes = signal<CSSClassesType>(getEmptyCssClasses());

  // Signal to track HTML attributes
  private attrs = signal<HTMLAttributesType>(getEmptyHTMLAttributes());

  constructor(private activatedRoute: ActivatedRoute) { }

  /**
   * Retrieves a property from the layout configuration using a given path
   * @param {string} path - The path to the property in the layout config
   * @param {ILayout} [config] - Optional config to use instead of the default one
   * @returns {string | boolean | undefined | Object} - The value found at the specified path
   */
  getProp(path: string, config?: ILayout): string | boolean | undefined | Object {
    return config ? get(config, path) : get(this.layoutConfig(), path);
  }

  /**
   * Retrieves the base layout type, either from the route or local storage
   * @returns {LayoutType} - The base layout type
   */
  getBaseLayoutTypeFromRouteOrLocalStorage(): LayoutType {
    const routeData = this.activatedRoute.firstChild?.snapshot?.data;
    return routeData?.layout || this.getBaseLayoutTypeFromLocalStorage();
  }

  /**
   * Retrieves the base layout type from local storage
   * @returns {LayoutType} - The base layout type
   */
  getBaseLayoutTypeFromLocalStorage(): LayoutType {
    const layoutType = localStorage?.getItem(BASE_LAYOUT_TYPE_LOCAL_STORAGE_KEY) as LayoutType | null;

    if (!layoutType) {
      this.setBaseLayoutType(defaultBaseLayoutType);
      return defaultBaseLayoutType;
    }

    return layoutType;
  }

  /**
   * Retrieves the layout configuration by type
   * @param {LayoutType | undefined} layoutType - The layout type
   * @returns {ILayout} - The layout configuration object
   */
  getLayoutByType(layoutType: LayoutType | undefined): ILayout {
    return layoutType === 'dark-sidebar' ? DarkSidebarConfig : defaultLayoutConfig;
  }

  /**
   * Retrieves the layout configuration, either from local storage or by type
   * @param {LayoutType} layoutType - The layout type
   * @returns {ILayout} - The layout configuration object
   */
  getLayoutConfig(layoutType: LayoutType): ILayout {
    const configInString = localStorage?.getItem(`${layoutType}-${LAYOUT_CONFIG_LOCAL_STORAGE_KEY}`);

    if (configInString) {
      try {
        return JSON.parse(configInString) as ILayout;
      } catch (ex) {
        console.error('Reading config exception:', ex);
      }
    }

    return this.getLayoutByType(layoutType);
  }

  /**
   * Sets the base layout type and saves it to local storage
   * @param {LayoutType} layoutType - The layout type
   */
  setBaseLayoutType(layoutType: LayoutType): void {
    const config = this.getLayoutByType(layoutType);

    localStorage?.setItem(BASE_LAYOUT_TYPE_LOCAL_STORAGE_KEY, layoutType);
    localStorage?.setItem(`${layoutType}-${LAYOUT_CONFIG_LOCAL_STORAGE_KEY}`, JSON.stringify(config));
  }

  /**
   * Reinitializes layout properties by resetting CSS classes and HTML attributes
   */
  reInitProps(): void {
    this.classes.set(getEmptyCssClasses());
    this.attrs.set(getEmptyHTMLAttributes());
  }
}
