// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
// Material
import { MatSnackBar } from '@angular/material/snack-bar';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Store
import { Store, select } from '@ngrx/store';
import { currentUser } from 'src/app/core/auth';
import { AppState } from 'src/app/core/reducers';
// RxJs
import { Observable, Subscription } from 'rxjs';
import { isEqual } from 'lodash';
// Service
import { LayoutUtilsService } from '@core/utils';
import { NgxRolesService } from 'ngx-permissions';
// Model
import { IUserAccount, IUserCompanies } from 'src/app/core/auth/_models/user.model';
// Constants
import { APP_ROLES } from '@constants';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UserInfoComponent implements OnInit, OnDestroy {

	// Public
	isSuperAdmin: boolean = true;
	profile$: Observable<IUserAccount>;
	languageChange$: Observable<string>;

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Creates an instance of UserInfoComponent.
	 * @param {TranslocoService} translocoService
	 * @param {Store<AppState>} store
	 * @param {LayoutUtilsService} layoutUtilsService
	 * @param {Router} router
	 * @param {NgxRolesService} ngxRolesService
	 * @param {MatSnackBar} snackBar
	 * @memberof UserInfoComponent
	 */
	constructor(
		private readonly translocoService: TranslocoService,
		private readonly store: Store<AppState>,
		private readonly layoutUtilsService: LayoutUtilsService,
		private readonly router: Router,
		private readonly ngxRolesService: NgxRolesService,
		private readonly snackBar: MatSnackBar
	) { }

	/**
	 * OnInit
	 * @memberof UserInfoComponent
	 */
	ngOnInit(): void {
		// Get User
		this.profile$ = this.store.pipe(select(currentUser));
		// Get Current live language
		this.languageChange$ = this.translocoService.langChanges$;
		// Check if user has Superadmin role
		this.isSuperAdmin = this.ngxRolesService.getRole(APP_ROLES.SUPERADMIN)?.name === APP_ROLES.SUPERADMIN;
	}

	/**
	 * OnDestroy
	 * @memberof QuickCartViewComponent
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Log out
	 * @returns void
	 */
	openLogoutConfirmation(): void {
		this.layoutUtilsService.showLogoutConfirmation();
	}

	/**
	 * Open switch company access confirmation modal and pass data
	 * @returns void
	 */
	onAccessCompany(switchTo: IUserCompanies, currentCompanyName: any): void {
		if (!isEqual(currentCompanyName, switchTo.name)) {
			const info = {
				currentCompanyName,
				switchTo
			};
			this.layoutUtilsService.showSwitchCompanyConfirmation(info);
		}
	}

	/**
	 * Naviagte To User Profile
	 * @returns void
	 */
	naviagteToUserProfile(): void {
		this.snackBar.open('[To-Do]: Implement User pofile');
		// this.router.navigate(['/register-management/accounts/users/user-profile']);
	}
}
