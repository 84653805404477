<!--begin::Menu wrapper-->
<div id="kt_app_sidebar_menu_wrapper" class="app-sidebar-wrapper hover-scroll-overlay-y my-5" data-kt-scroll="true"
  data-kt-scroll-activate="true" data-kt-scroll-height="auto"
  data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
  data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">


  <!--begin::Menu-->
  <div class="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true"
    data-kt-menu-expand="false">
    <!-- Dashboard -->
    <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/dashboard" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="element-11" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title" transloco="label_dashboard"></span>
      </a>
    </div>


    <!-- Begin:: INVENTORY MANAGEMENT -->
    <ng-template ngxPermissionsOnly="canAccessInventoryManagement">
      <!-- Separator -->
      <div class="menu-item">
        <div class="menu-content pt-5 pb-2">
          <span class="menu-section text-muted text-uppercase fs-8 ls-1" transloco="label_inventory_management"></span>
        </div>
      </div>

      <!-- Inventory Management -->
      <div class="menu-item menu-accordion" data-kt-menu-trigger="click">
        <span class="menu-link">
          <span class="menu-icon">
            <app-keenicon name="book" type="duotone" class="fs-2"></app-keenicon>
          </span>
          <span class="menu-title" transloco="label_inventory"></span>
          <span class="menu-arrow"></span>
        </span>
        <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active"
              routerLink="/inventory-management/stored-vehicles/list">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_vehicles"></span>
            </a>
          </div>
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/inventory-management/search-part">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_search_parts"></span>
            </a>
          </div>
          <div *ngxPermissionsOnly="['canCreateInventoryVehiclePart']" class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active"
              routerLink="/inventory-management/create-unattached-part">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_create_unattached_part"></span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- end:: INVENTORY MANAGEMENT -->


    <!-- Begin:: ORDER MANAGEMENT -->
    <ng-template ngxPermissionsOnly="canAccessOrdersManagement">
      <!-- Separator -->
      <div class="menu-item">
        <div class="menu-content pt-5 pb-2">
          <span class="menu-section text-muted text-uppercase fs-8 ls-1" transloco="label_order_management"></span>
        </div>
      </div>

      <!-- Order Management -->
      <div class="menu-item menu-accordion" data-kt-menu-trigger="click">
        <span class="menu-link">
          <span class="menu-icon">
            <app-keenicon name="abstract-44" type="duotone" class="fs-2"></app-keenicon>
          </span>
          <span class="menu-title" transloco="label_orders">
          </span>
          <span class="menu-arrow"></span>
        </span>
        <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
          <!-- begin:: All orders -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [routerLinkActiveOptions]="{exact: true}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_all_orders"></span>
            </a>
          </div>
          <!-- end:: All orders -->

          <!-- begin:: Ordered -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.2'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_ordered"></span>
            </a>
          </div>
          <!-- end:: Ordered -->

          <!-- begin:: Processing -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.14'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_processing"></span>
            </a>
          </div>
          <!-- end:: Processing -->

          <!-- begin:: Confirmed -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.3'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_confirmed"></span>
            </a>
          </div>
          <!-- end:: Confirmed -->

          <!-- begin:: Delivered -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.4'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_delivered"></span>
            </a>
          </div>
          <!-- end:: Delivered -->

          <!-- begin:: Cancelled -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.5'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_cancelled"></span>
            </a>
          </div>
          <!-- end:: Cancelled -->

          <!-- begin:: Awaiting Payment -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.6'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_awaiting_payment"></span>
            </a>
          </div>
          <!-- end:: Awaiting Payment -->

          <!-- begin:: Reserved -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.7'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_reserved"></span>
            </a>
          </div>
          <!-- end:: Reserved -->

          <!-- begin:: Return Requested -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.8'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_return_requested"></span>
            </a>
          </div>
          <!-- end:: Return Requested -->

          <!-- begin:: Return Accepted -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.9'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_return_accepted"></span>
            </a>
          </div>
          <!-- end:: Return Accepted -->

          <!-- begin:: Pending Delivery -->
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/orders-management/orders"
              [queryParams]="{order_state:'eq.13'}">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title" transloco="label_pending_delivery"></span>
            </a>
          </div>
          <!-- end:: Pending Delivery -->
        </div>
      </div>
    </ng-template>
    <!-- end:: ORDER MANAGEMENT -->

    <!-- Begin:: ACCESS -->
    <ng-template ngxPermissionsOnly="canAccessRolePermissionManagement">
      <!-- Separator -->
      <div class="menu-item">
        <div class="menu-content pt-5 pb-2">
          <span class="menu-section text-muted text-uppercase fs-8 ls-1">Access</span>
        </div>
      </div>

      <!-- Roles & Permissions -->
      <div class="menu-item menu-accordion" data-kt-menu-trigger="click">
        <span class="menu-link">
          <span class="menu-icon">
            <app-keenicon name="data" type="duotone" class="fs-2"></app-keenicon>
          </span>
          <span class="menu-title">Roles & Permissions
          </span>
          <span class="menu-arrow"></span>
        </span>
        <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/role-permission-management/roles">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title">Roles</span>
            </a>
          </div>
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active"
              routerLink="/role-permission-management/permissions">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title">Permissions</span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- end:: ACCESS -->

    <!-- Begin:: AUTHORING -->
    <ng-template ngxPermissionsOnly="canAccessAuthoring">
      <div class="menu-item">
        <div class="menu-content pt-5 pb-2">
          <span class="menu-section text-muted text-uppercase fs-8 ls-1">Translations</span>
        </div>
      </div>
      <div class="menu-item menu-accordion" data-kt-menu-trigger="click">
        <span class="menu-link">
          <span class="menu-icon">
            <app-keenicon name="abstract-25" type="duotone" class="fs-2"></app-keenicon>
          </span>
          <span class="menu-title">Authoring
          </span>
          <span class="menu-arrow"></span>
        </span>
        <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/authoring/1/label">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title">
                Perfect
                <span
                  class="badge badge-exclusive badge-light-danger fw-semibold fs-9 px-2 py-1 ms-3">Depreciated</span>
              </span>
            </a>
          </div>
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/authoring/2/label">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title">Prime</span>
            </a>
          </div>
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/authoring/3/label">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title">Too-Perfect</span>
            </a>
          </div>
          <div class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/authoring/4/label">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title">Notifications</span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- end:: AUTHORING -->

    <!-- Begin:: Register Management -->
    <ng-template ngxPermissionsOnly="canAccessRegisterManagement">
      <!-- Separator -->
      <div class="menu-item">
        <div class="menu-content pt-5 pb-2">
          <span class="menu-section text-muted text-uppercase fs-8 ls-1" transloco="label_register_management"></span>
        </div>
      </div>

      <!-- Accounts -->
      <div class="menu-item menu-accordion" data-kt-menu-trigger="click">
        <span class="menu-link">
          <span class="menu-icon">
            <app-keenicon name="grid" type="duotone" class="fs-2"></app-keenicon>
          </span>
          <span class="menu-title" transloco="label_accounts"></span>
          <span class="menu-arrow"></span>
        </span>
        <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
          <!-- Companies -->
          <div *ngxPermissionsOnly="'canAccessCompanyProfile'" class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active"
              routerLink="/register-management/accounts/companies" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{'limit':10}">
              <span class="menu-icon">
                <app-keenicon name="financial-schedule" type="duotone" class="fs-2"></app-keenicon>
              </span>
              <span class="menu-title" transloco="label_companies"></span>
            </a>
          </div>
          <!-- Companies Reviews chart -->
          <div *ngxPermissionsOnly="'canAccessCompanyReviewChart'" class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active"
              routerLink="/register-management/accounts/companies/reviews" [queryParams]="{'limit':10}" [routerLinkActiveOptions]="{ exact: true }">
              <span class="menu-icon">
                <app-keenicon name="abstract-23" type="duotone" class="fs-2"></app-keenicon>
              </span>
              <span class="menu-title" transloco="label_reviews"></span>
            </a>
          </div>
          <!-- Users -->
          <div *ngxPermissionsOnly="'canAccessUsersList'" class="menu-item">
            <a class="menu-link without-sub" routerLinkActive="active" routerLink="/register-management/accounts/users"
              [queryParams]="{'limit':10}">
              <span class="menu-icon">
                <app-keenicon name="people" type="duotone" class="fs-2"></app-keenicon>
              </span>
              <span class="menu-title" transloco="label_users"></span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- end:: Register Management -->

  </div>
  <!--end::Menu-->
</div>
<!--end::Menu wrapper-->