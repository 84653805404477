// Angular
import { Component, ViewEncapsulation } from '@angular/core';
// App Version
import packageInfo from 'package.json';
// Service
import { LogoService } from 'src/app/_metronic/layout';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body[app-root]',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AuthComponent {

	// Public properties
	today: number = Date.now();
	appVersion = packageInfo.version;
	logoSrc: string;

	/**
	 * Creates an instance of AuthComponent.
	 * @memberof AuthComponent
	 */
	constructor(
		private readonly logoService: LogoService
	) {
		this.logoSrc = this.logoService.getLogoSrc();
	}
}
