// Angular
import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY/MM',
    },
    display: {
        dateInput: 'YYYY/MM',
        monthYearLabel: 'YYYY MMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY MMMM',
    },
};

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[monthYearDateFormat]',
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    standalone: false
})
export class MonthYearDateFormatDirective {
}